import React, { useMemo } from 'react';
import { UiText, UiButton } from '@dex/bubl-ui';
import styles from "./Button.module.scss";

const Button: React.FC<ButtonProps> = (props) => {

    const rest = { ...props };

    return useMemo(() => {

        const classes = [styles.button];
        const classesLabel = [styles.label];

        if (props.theme) {
            classes.push(styles[`button-${props.theme}`])
            classesLabel.push(styles[`label-${props.theme}`])
        }

        return (

            <UiButton
                {...rest}
                onClick={props.loading ? undefined : props.onClick}
                path={props.loading ? undefined : props.path}
                className={classes}
                ripple={true}
            >

                {props.label &&
                    <UiText
                        className={classesLabel}
                    >
                        {props.label}

                    </UiText>
                }

            </UiButton>

        )

    }, [props]);

}

interface ButtonProps {
    label: string,
    theme?: string,
    loading?: boolean,
    [key: string]: any,
}

export default Button;

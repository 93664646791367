import React from "react";

import styles from "./PageNotFoundScreen.module.scss";

import { UiText } from "@dex/bubl-ui";

const PageNotFoundScreen: React.FC<PageNotFoundScreenProps> = (props) => {

    return (

        <>

            <UiText>Page Not Found</UiText>

        </>

    );
};

interface PageNotFoundScreenProps {
    [key: string]: any;
}

export default PageNotFoundScreen;
